<template>
    <div class="container repair-container">

        <div class="find-device" v-if="!device">
            <div class="col-md-12 mb-2">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Find by IMEI, Serial, Claim Number, or Asset ID.</label>
                        </div>
                        <div class="col">
                            <div class="form-group mb-0">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="fa fa-search mb-0"></i>
                                    </div>
                                    <input type="text" inputmode="numeric" pattern="[0-9]*" placeholder="Enter IMEI, Serial, Claim Number. or Asset ID" v-model="searchTerm" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-auto pl-0">
                            <button class="btn btn-success btn-block d-md-inline-block mb-0" :disabled="loading">
                                <span @click="findDevice()">Find Device</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mx-auto" v-if="loading">
                    <h1 class="text-center">Loading Device Details...</h1>
                </div>
            </div>
        </div>

        <!-- Links -->
        <div class="upload-photos" v-if="device">

            <div class="card card-body">
                <div class="row">
                    <div class="col-5">
                        <img :src="device.model_version.image.path" :alt="device.model_version.image.title" class="img-fluid">
                    </div>
                    <div class="col-7">
                        <p class="mb-0">Order ID: {{device.order_id}}</p>
                        <p class="mb-0">Device ID: {{device.id}}</p>
                        <p class="mb-0" v-if="device.asset">Asset ID: {{device.asset.id}}</p>
                        <p class="mb-0">IMEI: {{device.imei}}</p>
                        <p class="mb-0" v-if="device.customer_reference">Claim No: {{device.customer_reference}}</p>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!photoType">
                <div class="col-12">
                    <label>Are these photos inbound or outbound?</label>
                    <div class="card-grid grid-col-2 mb-0">
                        <div class="card-link-container">
                            <input type="radio" name="photoType[]" value="inbound" v-model="photoType" id="inbound" class="d-none">
                            <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info h-auto"
                                   for="inbound">
                                <icon-device-front
                                    size="md"
                                    class="icon-all-white m-0 mr-2">
                                </icon-device-front>
                                Inbound Photos
                            </label>
                        </div>
                        <div class="card-link-container">
                            <input type="radio" name="photoType[]" value="outbound" v-model="photoType" id="outbound" class="d-none">
                            <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info h-auto"
                                   for="outbound">
                                <icon-device-front
                                    size="md"
                                    class="icon-all-white m-0 mr-2">
                                </icon-device-front>
                                Outbound Photos
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-4" v-if="photoType">
                <div class="col-12">
                    <label>What type of photo are you adding?</label>
                        <div class="card-grid grid-col-2 mb-0">
                        <div class="card-link-container">
                            <input type="file" name="frontPhoto" id="frontPhoto" class="file-input" accept="image/*"
                                   capture="environment" @change="uploadPhoto(`${inboundOrOutbound}-front`, 'frontPhoto')">
                            <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info"
                                   for="frontPhoto">
                                <icon-device-front
                                    size="md"
                                    class="icon-all-white m-0 mr-2">
                                </icon-device-front>
                                Front Photo
                            </label>
                        </div>
                        <div class="card-link-container">
                            <input type="file" name="backPhoto" id="backPhoto" class="file-input" accept="image/*"
                                   capture="environment" @change="uploadPhoto(`${inboundOrOutbound}-back`, 'backPhoto')">
                            <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info"
                                   for="backPhoto">
                                <icon-device-back
                                    size="md"
                                    class="icon-all-white m-0 mr-2">
                                </icon-device-back>
                                Back Photo
                            </label>
                        </div>
                        <div class="card-link-container">
                            <input type="file" name="sidePhoto" id="sidePhoto" class="file-input" accept="image/*"
                                   capture="environment" @change="uploadPhoto(`${inboundOrOutbound}-side`, 'sidePhoto')">
                            <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info"
                                   for="sidePhoto">
                                <icon-camera
                                    size="md"
                                    class="icon-all-white m-0 mr-2">
                                </icon-camera>
                                Side Photo
                            </label>
                        </div>
                        <div class="card-link-container">
                            <input type="file" name="accessoriesPhoto" id="accessoriesPhoto" class="file-input" accept="image/*"
                                   capture="environment"
                                   @change="uploadPhoto(`${inboundOrOutbound}-accessories`, 'accessoriesPhoto')">
                            <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info"
                                   for="accessoriesPhoto">
                                <icon-checklist
                                    size="md"
                                    class="icon-all-white m-0 mr-2">
                                </icon-checklist>
                                Accessories
                            </label>
                        </div>
                </div>
            </div>
            </div>

            <div class="row" v-if="device && photoType">
                <div class="col-12">

                    <label>Uploaded Photos</label>

                    <!-- Empty state -->
                    <div class="card card-body" v-if="!uploaded.length">
                        <empty-state
                            emptyIcon
                            icon="camera"
                            title="No photos uploaded"
                            subtitle="Select one of the options above to add a photo">
                        </empty-state>
                    </div>
                    <!-- List of photos -->
                    <template>
                        <div class="card-grid grid-col-2 grid-col-md-3">
                            <image-item
                                v-for="(imagePath, key) in uploaded" :key="key"
                                :imageType="imagePath.type"
                                :imageData="imagePath.path">
                            </image-item>
                        </div>
                    </template>

                    <!-- Image preview modal -->
                    <repair-image-modal></repair-image-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import Compressor from 'compressorjs';

export default {

    data() {
        return {
            device: null,
            photoType: null,
            uploaded: [],
            searchTerm: null,
            loading: false
        }
    },

    mounted() {
        if(this.$route.params.imei) {
            this.searchTerm = this.$route.params.imei;
            this.findDevice();
            if(this.$route.params.phototype) {
                this.photoType = this.$route.params.phototype;
            }
        }
    },

    computed: {
        inboundOrOutbound() {
            return this.photoType;
        },
    },

    methods: {
        ...mapActions([
            "displayToast",
        ]),

        findDevice() {
            this.loading = true;
            this.$http.post(`/api/v4/moh/findDeviceForPhotoUpload`, {search_term: this.searchTerm}).then((response) => {
               this.device = response.data.data;
               this.loading = false;
            }).catch(() => {
                this.displayToast({text: 'No device could be located.', type: 'error', duration: 8000});
                this.loading = false;
            });
        },

        uploadPhoto(type, elementId) {
            const filePicker = document.getElementById(elementId);

            if (!filePicker.files || filePicker.files.length <= 0) {
                this.displayToast({text: 'No file selected.', type: 'error'});
                return;
            }

            let self = this;
            new Compressor(filePicker.files[0], {
                quality: 0.6,
                success(result) {

                    const data = new FormData();
                    data.append('image', result);
                    data.set('device_id', self.device.asset.assetable_id);
                    data.set('type', type);

                    self.displayToast({text: 'File is being uploaded.', type: 'success'});

                    self.$http.post(
                        '/api/v4/moh/upload-image',
                        data,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    ).then((response) => {
                        self.uploaded.push(response.data.data);
                    }).catch((error) => {
                        console.log(error);
                        const message = error?.response?.statusText ?? 'Error uploading file';
                        self.displayToast({text: message, type: 'error'});
                    });

                },
                error() {
                    self.displayToast({text: 'Could not parse file.', type: 'error'});
                    filePicker.value = "";
                },
            });
        }

    }
}
</script>

<style scoped>
.input-group .input-group-prepend {
    left: 15px;
    top: 15px;
    color: #b7baca;
}
</style>
